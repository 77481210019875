<template>
  <component
    :is="tag"
    class="base-button"
    :class="variant"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
const VARIANTS = ["primary", "primary-outline"];
export default {
  props: {
    tag: {
      type: String,
      default: "button",
    },
    variant: {
      type: String,
      validation: (value) => VARIANTS.includes(value),
      default: "primary",
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: #bd7eee;

.base-button {
  min-width: 4rem;
  cursor: pointer;
  outline: none;
  background: transparent;
  border: none;
  font-size: 1.2rem;
  padding: 1rem;

  &.primary {
    color: $primary;
    &-outline {
      color: $primary;
      border: 1px solid $primary;
      border-radius: 0.5rem;
    }
  }
}
</style>
