<template>
  <div>
    <component :is="isMainApp ? 'Rightsholders' : 'router-view'" />
  </div>
</template>

<script>
import { TEST_BULK, TEST_PLAYLISTS } from "@/constants/apps";
const Rightsholders = () => import("@/views/Rightsholders");

export default {
  components: {
    Rightsholders,
  },
  computed: {
    isMainApp() {
      const host = window.location.host;
      const firstPart = host.split(".")[0];
      if (
        process.env.NODE_ENV !== "production" &&
        (TEST_BULK || TEST_PLAYLISTS)
      )
        return false;

      if (["bulk", "playlist"].includes(firstPart)) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>