import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import rightsholdersRoutes from './rightsholdersRoutes';
import bulkRoutes from './bulkRoutes';
import playlistsRoutes from './playlistRoutes';
import { TEST_BULK, TEST_PLAYLISTS } from '@/constants/apps';
const host = window.location.host;
const parts = host.split('.');
let firstPart = parts[0];

if (process.env.NODE_ENV !== 'production') {
  if (TEST_BULK) {
    firstPart = 'bulk';
  } else if (TEST_PLAYLISTS) {
    firstPart = 'playlist';
  }
}

const routesConfig = {
  rightsholders: rightsholdersRoutes,
  bulk: bulkRoutes,
  playlist: playlistsRoutes,
};

let routes = Reflect.has(routesConfig, firstPart)
  ? routesConfig[firstPart]
  : routesConfig.rightsholders;

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
